@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/_modal.scss';

html {
    height: 100vh;
    font-size: var(--html-font-size) !important;
}

body {
    /* font-weight: 300 !important; */
    height: auto;
    /* font-size: var(--body-font-size) !important; */
}

html,
body {
    font-family: var(--font) !important;
    background-color: var(--base00) !important;
    color: var(--base04) !important;
    fill: var(--base04) !important;

    margin: 0;
    padding: 0;
}

h4 {
    color: var(--active) !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-default {
    cursor: default !important;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.cursor-text {
    cursor: text !important;
}

.split {
    border-top: 1px solid grey;
    margin: 0;
}

.gibson {
    font-family: Gibson !important;
}

.img-responsive {
    max-width: 100%;
    height: auto;
    display: block;
}

.popover {
    color: var(--dark);
}

.dropdown-menu {
    background-color: var(--base00) !important;
    color: var(--base04) !important;

    a {
        color: var(--base04) !important;

        &:hover {
            color: var(--dark) !important;
        }

        &.active {
            color: var(--white) !important;
        }
    }
}

/* ################################# Donnee ##########################################################################*/

.panDonTitleTheme {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 10px;
    cursor: pointer;
}

/* ###################### Main #######################################################################################*/

.windowAdmin {
    width: 100%;
    overflow: visible;
    font-size: 14px;
}
.windowAdmin h4 {
    font-size: 18px;
}
.admin_maincontenair {
    max-width: 1400px;
}

.bordureExtContenair {
    min-width: 60px;
}
.adm_main_title {
    font-size: 24px;
    padding-top: 20px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: solid 1px var(--base02);
}

.modalTable {
    table-layout: fixed;
    width: 100%;
    padding-bottom: 5px;
    /*margin: 20px;*/
    border-bottom: 1px solid var(--base02); /*#ddd*/

    th {
        background: var(--info);
        /*background: -webkit-linear-gradient(top, var(--primary), var(--info));*/
        /*background: -o-linear-gradient(top, var(--primary), var(--info));*/
        /*background: -moz-linear-gradient(top, var(--primary), var(--info));*/
        /*background: linear-gradient(to bottom, var(--primary), var(--info));*/
        text-align: left;
        padding: 7px 5px 15px 5px;
        color: white;
        font-weight: bold;
        vertical-align: middle;
    }
    td {
        text-align: left;
        padding: 10px 5px 10px 5px;
        vertical-align: middle;
        border-bottom: 1px solid var(--base01);
        height: 0;
        overflow: hidden !important;
        white-space: normal !important;
        text-overflow: ellipsis !important;
    }
    tr:last-child td {
        border-bottom: 3px double var(--base01);
    }
    tr {
        height: 30px !important;
        border-left: 1px solid var(--base01);
        border-right: 1px solid var(--base01);

        &:hover {
            background-color: var(--base01);
        }

        &.choixCouleur {
            height: 150px;
            overflow: auto;
            display: -webkit-flex;
            -webkit-flex-direction: column;
            -webkit-justify-content: flex-start;
            -webkit-align-items: stretch;
            -webkit-flex-wrap: nowrap;
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            flex-direction: column;
            flex-wrap: nowrap;
            cursor: pointer;
        }
    }
}

/* ###################### UPDATE GROUP  ################################################################################*/
.adm_groupWraperTheme {
    padding-bottom: 10px;
}
.adm_groupTitleTheme {
    font-size: 16px;
    font-weight: bold;
}

.iconQuickSelect {
    margin-left: 15px;
    cursor: pointer;

    &:hover {
        color: var(--active);
    }
}

.adm_groupWraperSSTheme {
    padding-bottom: 10px;
    padding-left: 20px;
    padding-top: 5px;
}
.adm_groupTitleSSTheme {
    font-size: 14px;
    font-weight: bold;
}

/*####################################################################################################################*/

div.flexComponentOne.ng-scope {
    text-align: center;
}

[hidden] {
    display: none !important;
}

/* bootstrap modal */
.modal-content {
    background-color: var(--base00) !important;
}

.modal-dialog.cadastre_esterel .modal-content {
    background-color: #273b5c !important;

    .modal-header {
        flex-direction: column;
        border-bottom: none;

        .modal-title {
            color: #e7a129 !important;

            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
        }
    }

    .modal-body {
        color: #ffffff;
        text-align: center;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;

        > *:not(:last-child) {
            margin-bottom: 20px;
        }

        a {
            color: #ffffff !important;
            font-weight: 500;
            font-size: 16px;
        }
    }

    .modal-footer {
        border-top: none;
        justify-content: center;
        padding-bottom: 0;

        img {
            margin: 0;
        }
    }
}

/* NgxSmartModal */
.nsm-overlay-open {
    height: 100vh;
}
.nsm-content {
    background-color: var(--base00) !important;
    padding: 0 !important;
}

/* bootstrap btn group dropdown split */
.dropdown-toggle-split {
    border-top-right-radius: var(--bs-btn-border-radius) !important;
    border-bottom-right-radius: var(--bs-btn-border-radius) !important;
}

/* bootstrap accordion */
.accordion-button:focus {
    z-index: 1;
}

.accordion-body {
    padding: 0.75rem !important;
}

/* color table */
.cubeStrech {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-width: 15px;
    height: 15px;
    margin: 1px 0 1px 0;
    border-radius: 0;
}

.barreCouleur {
    display: -webkit-flex;
    -webkit-flex-direction: row;
    -webkit-justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-left: 1px;
    padding-right: 1px;
    width: 100%;
    cursor: pointer;

    &:hover {
        background-color: black;
        color: #ffffff;
        /*background-color: rgba(93, 156, 236, 1);*/
    }
}

.continuous-line {
    height: 5px;
    width: 98%;
    background-color: black;
}

.discreet-line {
    height: 5px;
    background-color: black;
    border-radius: 100%;
}

.pattern-line:not(.selected):hover {
    opacity: 0.75;
}

.pattern-line {
    opacity: 0.5;
}

.pattern-line.selected {
    opacity: 1;
}

.leaflet-touch .leaflet-bar button.btn {
    width: 100%;
    height: initial;
}

// custom bootstrap select
[data-st-theme='dark'] .form-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important;
}

[data-st-theme='cadastre_bdr'] .form-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important;
}

// svg color from cs new element panel
[data-st-theme='dark'] #cs-new-element-panel path {
    fill: white !important;
}

[data-st-theme='cadastre_bdr'] #cs-new-element-panel path {
    fill: white !important;
}
