#popover {
    /*position:absolute;*/
    /*z-index:9999;*/
    color: #4a4a4c;
    /*/!*font-size:10px;*!/*/
    /*border: 1px solid darkgrey;*/
    /*background-color: white;*/
    /*-webkit-border-radius: 6px;*/
    /*-moz-border-radius: 6px;*/
    /*border-radius: 6px;*/
    /*-moz-box-shadow: 2px 2px 4px 0px #9b9b9b;*/
    /*-webkit-box-shadow: 2px 2px 4px 0px #9b9b9b;*/
    /*-o-box-shadow: 2px 2px 4px 0px #9b9b9b;*/
    /*box-shadow: 2px 2px 4px 0px #9b9b9b;*/
    /*filter:progid:DXImageTransform.Microsoft.Shadow(color=#9b9b9b, Direction=135, Strength=3);*/
}

#popover .popBody {
    padding: 15px;
}
#popover.mini .popBody {
    padding: 4px 7px 4px 7px;
    font-size: 11px;
    line-height: normal;
}

/*#popover.top.mini .arrow {*/
/*bottom: -7px !important;*/
/*border-width: 7px !important;;*/
/*border-bottom-width: 7px !important;;*/
/*}*/
/*#popover.bottom.mini .arrow {*/
/*top: -7px !important;*/
/*border-width: 7px !important;;*/
/*border-top-width: 7px !important;;*/
/*}*/
#popover.left.mini .arrow {
    right: -14px !important;
    border-width: 7px !important;
    border-right-width: 7px !important;
    margin-top: -7px !important;
}

/*#popover.right.mini .arrow {*/
/*left: -7px !important;*/
/*border-width: 7px !important;;*/
/*border-left-width: 7px !important;;*/
/*}*/
