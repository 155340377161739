@import '../../../../../node_modules/bootstrap/scss/functions';
@import '../../../../../node_modules/bootstrap/scss/variables';
@import '../../../../../node_modules/bootstrap/scss/mixins';

@font-face {
    font-family: 'Montserrat';
    src: url('../../../../assets/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

#header-esterel {
    font-family: 'Montserrat', sans-serif !important;
    background-color: #273b5c;

    .navbar-toggler {
        color: white;
        border-color: white;
        width: 50px;
        height: 40px;
        font-weight: 600;
        font-size: 26px;
        padding-top: 6px;
    }

    .navbar-nav {
        .btn-cta-esterel {
            background-color: #273b5c;
            color: #ffffff;
            border-radius: 10rem;
            border: 1px solid #ffffff;
            display: flex;
            align-items: center;
            gap: 1rem;
            height: 40px;
            padding-left: 1rem;
            padding-right: 1rem;
            font-size: 16px;
            font-weight: 600;

            &:hover {
                background-color: #273b5c;
                color: #ffffff;
            }

            &:active {
                background-color: #273b5c !important;
                color: #ffffff !important;
                border-color: #ffffff !important;
            }
        }

        a {
            display: flex;
            align-items: center;
            width: fit-content;
            border-radius: 10rem;
            border: 1px solid #ffffff;
            padding: 0.5rem 1rem;
            background-color: #273b5c;
            color: #ffffff;
            font-size: 16px;
            font-weight: 600;

            height: 40px;
            padding: 0 1rem;

            span {
                margin-right: 1rem;
            }
        }

        @include media-breakpoint-up(lg) {
            .logout {
                border: none !important;
                margin-left: 1rem;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .collapse {
            &:not(.show) {
                flex-grow: 0;
            }

            .navbar-nav > *:not(:last-child) {
                margin-right: 1rem;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .collapse,
        .collapsing {
            a,
            button {
                width: 100%;
                display: flex;
                justify-content: center;
            }

            .navbar-nav > *:not(:last-child) {
                margin-bottom: 1rem;
            }
        }
    }
}
