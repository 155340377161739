@import '../../../../../node_modules/bootstrap/scss/functions';
@import '../../../../../node_modules/bootstrap/scss/variables';
@import '../../../../../node_modules/bootstrap/scss/mixins';

@font-face {
    font-family: 'Montserrat';
    src: url('../../../../assets/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

#login-esterel {
    font-family: 'Montserrat', sans-serif !important;

    .main-container {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100vh;

        .background-esterel {
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;

            background-color: #273b5c;

            .top {
                min-height: 350px;
                background-repeat: no-repeat;
                background-size: 100% 60%;
                background-position: top;
            }

            @include media-breakpoint-up(sm) {
                .top {
                    background-size: 100% 70%;
                }
            }

            @include media-breakpoint-up(md) {
                .top {
                    background-size: 100% 80%;
                }
            }

            @include media-breakpoint-up(lg) {
                .top {
                    background-size: 100% 90%;
                }
            }

            @include media-breakpoint-up(xl) {
                .top {
                    background-size: 100% 100%;
                }
            }

            .bottom {
                flex-grow: 1;
            }
        }

        .content-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 15px;
            overflow: auto;

            .header {
                display: flex;
                justify-content: end;

                a {
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    border-radius: 10rem;
                    padding: 0rem 1rem;
                    background-color: #273b5c;
                    color: #ffffff;
                    height: 40px;
                    font-size: 16px;
                    font-weight: 600;

                    span {
                        margin-right: 1rem;
                    }

                    &:not(:last-child) {
                        margin-right: 1rem;
                    }
                }

                button {
                    height: 40px;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    font-size: 16px;
                    font-weight: 600;
                }
            }

            .body-container {
                text-align: center;

                display: flex;
                flex-direction: column;
                align-items: center;

                > * {
                    max-width: 845px;
                }

                .title {
                    font-style: normal;
                    font-weight: 600;
                    color: #ffffff;
                }

                .call-to-action {
                    width: 100%;

                    .input-search-container {
                        position: relative;
                        display: flex;
                        align-self: center;
                        border-radius: 10rem;
                        background-color: var(--white) !important;

                        label {
                            padding-left: 1rem;
                            padding-right: 0;
                            display: flex;
                            align-items: center;
                        }

                        input {
                            height: 100%;
                            border: none;
                            border-radius: 10rem;

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }

                    .geolocalize {
                        display: block;

                        button {
                            padding: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            background-color: #ffffff !important;
                            color: #273b5c !important;
                        }
                    }

                    a.access {
                        height: 48px;
                        display: flex;
                        align-items: center;
                        background-color: #ffffff;
                        color: #273b5c;
                        border-radius: 10rem;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                    }
                }

                .description {
                    font-weight: 500;
                    color: #ffffff;

                    .col-md {
                        @include media-breakpoint-up(md) {
                            text-align: start;
                        }

                        @include media-breakpoint-down(md) {
                            text-align: center;
                        }
                    }
                }

                .footer {
                    display: inline-block;
                    font-weight: 400;
                    text-align: center;
                    color: #ffffff;
                    width: 100%;
                }
            }
        }
    }

    .call-to-action {
        .dropdown-menu {
            left: calc(-23px - 1rem) !important;
            width: 100%;
            border-radius: 1.5rem;

            &.show {
                padding-top: 0;
                padding-bottom: 0;
            }

            .dropdown-item {
                background-color: transparent;
                border-width: 0;
                font-family: inherit;
                font-size: 14px;
                font-style: inherit;
                font-weight: inherit;
                line-height: inherit;
                padding: 3px 20px;
                width: 100%;
                text-align: left;
                color: var(--base03);

                &.active {
                    background-color: #2e6da4 !important;
                    color: var(--white) !important;

                    &:first-of-type {
                        border-top-left-radius: 1.5rem;
                        border-top-right-radius: 1.5rem;
                    }
                }
            }

            button:has(.is-disabled) {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;

                color: #e7a129;
                pointer-events: none;
                background-color: transparent;

                .is-disabled {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    > small:first-child {
                        margin-right: 2.5rem;
                    }

                    .dot-pulse,
                    .dot-pulse::after,
                    .dot-pulse::before {
                        background-color: #efbe2c;
                        color: #efbe2c;
                        width: 4px;
                        height: 4px;
                    }
                }
            }
        }
    }
}

html:not([data-ui]),
html[data-ui]:not([data-ui='mobile']) #login-esterel {
    height: 100vh;

    .main-container {
        .background-esterel {
            .top {
                background-image: url('/assets/img/login/csgp_esterel/login_background_esterel_cropped.jpg');
            }
        }

        .content-container {
            min-width: 475px;

            .header {
                padding: 10px;
            }

            .body-container {
                > *:not(:last-child) {
                    margin-bottom: 25px;
                }

                .logo-esterel {
                    margin-top: 60px;
                }

                @include media-breakpoint-up(sm) {
                    .logo-esterel {
                        margin-top: 75px;
                    }
                }

                @include media-breakpoint-up(md) {
                    .logo-esterel {
                        margin-top: 90px;
                    }
                }

                @include media-breakpoint-up(lg) {
                    .logo-esterel {
                        margin-top: 105px;
                    }
                }

                @include media-breakpoint-up(xl) {
                    .logo-esterel {
                        margin-top: 125px;
                    }
                }

                .title {
                    font-size: 22px;
                    line-height: 27px;
                }

                .call-to-action {
                    .input-search-container {
                        height: 48px;
                    }

                    .geolocalize {
                        height: 48px;
                        width: 48px;

                        button {
                            height: 48px;
                            width: 48px;
                        }
                    }

                    a.access {
                        height: 48px;
                    }
                }

                .description {
                    font-size: 16px;
                    line-height: 20px;
                }

                .footer {
                    font-size: 12px;
                    line-height: 15px;
                }
            }
        }
    }
}

html[data-ui='mobile'] #login-esterel {
    height: 100dvh;

    .main-container {
        .background-esterel {
            .top {
                background-image: url('/assets/img/login/csgp_esterel/login_background_esterel_cropped_mobile.jpg');
            }
        }

        .content-container {
            .header {
                a,
                button {
                    width: 40px;
                    height: 40px;
                    padding-left: 0;
                    padding-right: 0;
                    justify-content: center;
                }
            }

            .body-container {
                > *:not(:last-child) {
                    margin-bottom: 15px;
                }

                .logo-esterel {
                    margin-top: 80px;
                }

                .title {
                    font-size: 16px;
                    line-height: 20px;
                }

                .call-to-action {
                    .input-search-container {
                        height: 40px;
                    }

                    .geolocalize {
                        height: 40px;
                        width: 40px;

                        button {
                            height: 40px;
                            width: 40px;
                        }
                    }

                    a.access {
                        height: 40px;
                    }
                }

                .description {
                    font-size: 12px;
                    line-height: 16px;
                }

                .footer {
                    font-size: 8px;
                    line-height: 11px;
                }
            }
        }
    }
}

.btn-contact-esterel {
    background-color: #273b5c;
    color: #ffffff;
    border-radius: 10rem;
    display: flex;
    align-items: center;
    gap: 1rem;

    &:hover {
        background-color: #273b5c;
        color: #ffffff;
    }

    &:active,
    &:focus-visible {
        background-color: #273b5c !important;
        color: #ffffff !important;
        border-color: #ffffff !important;
    }
}
