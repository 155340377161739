@import '../../../../../node_modules/bootstrap/scss/functions';
@import '../../../../../node_modules/bootstrap/scss/variables';
@import '../../../../../node_modules/bootstrap/scss/mixins';

#header-arras {
    background: white;

    .title h2 {
        cursor: default;
        color: #0d5277;
        font-family: Poppins;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0;
    }

    .navbar-brand {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;

        a {
            margin-top: 1rem;
        }
    }
}

@include media-breakpoint-up(lg) {
    #header-arras {
        padding: 0;
    }

    #navbar-admin .collapse:not(.show),
    .header-container .collapse:not(.show),
    #header-container .collapse:not(.show) {
        display: inherit !important;
    }
}

.tutorial ul li {
    color: black;
}

.btn-outline-arras {
    background-color: transparent;
    color: #0d5277 !important;
    border-color: #0d5277 !important;
    outline: none !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.btn-outline-arras:focus,
.btn-outline-arras:hover {
    background-color: transparent;
    color: #efbe2c !important;
    border-color: #efbe2c !important;
    outline: none !important;
}

@include media-breakpoint-up(sm) {
    #header-arras {
        .title h2 {
            font-size: 1.8rem;
        }

        .navbar-brand {
            flex-direction: row;
        }

        #siterre-navbar-collapse {
            flex-direction: row;
        }

        .navbar-brand a {
            margin-top: 0;
            margin-left: 1rem;
        }

        .btn-outline-arras {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}
